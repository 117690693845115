// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

#cookieConsent a.cookieConsentOK {
  background-color: #0082c9;
  color: white;
  display: inline-block;
  border-radius: 20px;
  padding: 0 20px;
  cursor: pointer;
  margin: 0 0px 0 10px;
}
#cookieConsent a {
  color: #4B8EE7;
  text-decoration: none;
}